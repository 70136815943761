<template>
  <b-modal
    id="modalPrintConsultaExterna"
    hide-footer
    header-class="d-block"
    :selectedConsulta = '1'
  >
    <template #modal-header>
      <div class="row">
        <div class="col-sm-12">
          C.E. N° {{ selectedConsulta.numero }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <b>Paciente:</b> {{ selectedConsulta.paciente}}
        </div>
      </div>
    </template>
    <div class="row ml-0 mt-3">
      <div class="col-sm-6">
        <template v-if="itemService && itemService.length > 0">
        <div class="row mb-3" v-for="(categoria, index) in itemService" :key=index>
          <div class="col-sm-9">
            <button class="btn btn-block btn-success" @click="downloadPdf(categoria.categoria_id)"> {{categoria.categoria}} </button>
          </div>
          <div class="col-sm-3 pl-0 d-flex align-items-center">
            <button
              class="btn px-2 py-1 btn-secondary"
              @click="printPDF(categoria.categoria_id)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-printer"
                viewBox="0 0 16 16"
              >
                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                <path
                  d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2
                  2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2
                  2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2
                  2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2
                  2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1
                  1 0 0 1 1 1z"
                />
              </svg>
            </button>
          </div>
        </div>
        </template>
        <template v-else-if="itemService && itemService.length === 0">
          <div class="alert alert-warning" role="alert">
            No tiene Servicios
          </div>
        </template>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
              >
              <label class="form-check-label">Sin Logo</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
              >
              <label class="form-check-label">Sin Diagnóstico</label>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-8">
            <button class="btn btn-warning"
            @click="downloadHistorialPdf()"
            >
              Historia Clínica
            </button>
          </div>
          <div class="col-sm-3 pl-0">
            <button
              class="btn px-2 py-1 btn-secondary"
              @click="printHistorialPDF()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-printer"
                viewBox="0 0 16 16"
              >
                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                <path
                  d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2
                  2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2
                  2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2
                  2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2
                  2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1
                  1 0 0 1 1 1z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'ModalPrint',
  props: {
    selectedConsulta: {
      type: Object,
    },
    itemService: {
      type: Array, // Cambia el tipo de Object a Array
      default: () => [],
    },
  },
  data() {
    return {
      consultaId: null,
      nombrePaciente: '',
      numero: '',
    };
  },
  methods: {
    async downloadPdf(servicioId) {
      let RESPONSE;
      try {
        if (servicioId !== 8) {
          RESPONSE = await axios.get(`/clinic/consultation/patient/servicio/print/${this.selectedConsulta.consulta_id}`, {
            params: {
              servicio_complementario_id: servicioId,
            },
            responseType: 'blob',
          });
        } else {
          RESPONSE = await axios.get(`/clinic/consultation/patient/receta/print/${this.selectedConsulta.consulta_id}`, {
            params: {
              servicio_complementario_id: servicioId,
            },
            responseType: 'blob',
          });
        }
        const BLOB = new Blob([RESPONSE.data], { type: 'application/pdf' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        window.open(DOWNLOAD_URL, '_blank');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },

    async printPDF(servicioId) {
      let url;
      let params;
      if (servicioId !== 8) {
        url = `/clinic/consultation/patient/servicio/print/${this.selectedConsulta.consulta_id}`;
        params = {
          servicio_complementario_id: servicioId, // Asegúrate de obtener este valor de algún lugar
        };
      } else {
        url = `/clinic/consultation/patient/receta/print/${this.selectedConsulta.consulta_id}`;
        params = {
          servicio_complementario_id: servicioId, // Asegúrate de obtener este valor de algún lugar
        };
      }
      try {
        const response = await axios.get(url, { params, responseType: 'blob' });
        if (response && response.data) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const pdfUrl = URL.createObjectURL(blob);
          const iframe = document.createElement('iframe');
          iframe.src = pdfUrl;
          iframe.style.display = 'none';
          document.body.appendChild(iframe);
          // Esperar a que se cargue el PDF en el iframe antes de imprimir
          iframe.onload = function () {
            iframe.contentWindow.print();
          };
        } else {
          util.showNotify('La respuesta no contiene datos válidos', 'error');
        }
      } catch (error) {
        console.error('Error al descargar el PDF:', error);
        util.showNotify('Hubo un problema al obtener el PDF', 'error');
      }
    },
    async downloadHistorialPdf() {
      let RESPONSE;
      try {
        RESPONSE = await axios.get(`clinic/consultation/patient/historia_clinica/print/${this.selectedConsulta.consulta_id}`, {
          responseType: 'blob',
        });
        console.log(RESPONSE);
        const BLOB = new Blob([RESPONSE.data], { type: 'application/pdf' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        window.open(DOWNLOAD_URL, '_blank');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async printHistorialPDF() {
      let url;
      // eslint-disable-next-line prefer-const
      url = `clinic/consultation/patient/historia_clinica/print/${this.selectedConsulta.consulta_id}`;
      console.log(url);
      try {
        const response = await axios.get(url, { responseType: 'blob' });
        if (response && response.data) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const pdfUrl = URL.createObjectURL(blob);
          const iframe = document.createElement('iframe');
          iframe.src = pdfUrl;
          iframe.style.display = 'none';
          document.body.appendChild(iframe);
          // Esperar a que se cargue el PDF en el iframe antes de imprimir
          iframe.onload = function () {
            iframe.contentWindow.print();
          };
        } else {
          util.showNotify('La respuesta no contiene datos válidos', 'error');
        }
      } catch (error) {
        console.error('Error al descargar el PDF:', error);
        util.showNotify('Hubo un problema al obtener el PDF, vuelve a intentarlo', 'error');
      }
    },
  },
};
</script>
